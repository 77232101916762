import moment from "moment";
import { remove } from "ramda";
import Swal from "sweetalert2";

import ClientEmployeeDependent from "../graphql/client_employee_dependent.gql";
import api from "@/util/api";
import { getErrorCodeFromResponse } from "@/../frontend-common/utils/helper";
import Auth from "@/graphql/auth.gql";
import Client from "@/graphql/client.gql";
import ClientEmployee from "@/graphql/client_employee.gql";
import Timesheet from "@/graphql/timesheet.gql";
import ClientCustomVariable from "@/graphql/client_custom_variable.gql";
import ClientEmployeeCustomVariable from "@/graphql/client_employee_custom_variable.gql";
import CalculationSheetTemplate from "@/graphql/calculation_sheet_template.gql";
import CalculationSheetClientEmployee from "@/graphql/calculation_sheet_client_employee.gql";
import CalculationSheetVariable from "@/graphql/calculation_sheet_variables.gql";
import CalculationSheet from "@/graphql/calculation_sheet.gql";
import User from "@/graphql/user.gql";
import IglocalEmployee from "@/graphql/iglocal_employee.gql";
import IglocalAssignment from "@/graphql/iglocal_assignment.gql";
import SupportTicket from "@/graphql/support_ticket.gql";
import SupportTicketComment from "@/graphql/support_ticket_comment.gql";
import Formula from "@/graphql/formula.gql";
import WorkSchedule from "@/graphql/work_schedules.gql";
import WorkScheduleGroup from "@/graphql/work_schedule_groups.gql";
import WorkScheduleGroupTemplate from "@/graphql/work_schedule_group_templates.gql";
import Report from "@/graphql/report.gql";
import Approve from "@/graphql/approve.gql";
import ClientWorkflowSetting from "@/graphql/client_workflow_setting.gql";
import Notification from "@/graphql/notification.gql";
import KnowledgeQuestion from "@/graphql/knowledge_questions.gql";
import DebitNote from "@/graphql/debit_note.gql";
import SocialSecurityProfile from "@/graphql/social_security_profile.gql";
import Translation from "@/graphql/translation.gql";
import CalculationSheetTemplateAssignment from "@/graphql/calculation_sheet_template_assignments.gql";
import YearHoliday from "@/graphql/year_holiday.gql";
import CalculationSheetExportTemplate from "@/graphql/calculation_sheet_export_template.gql";
import SocialSecurityClaim from "@/graphql/social_security_claim.gql";
import PayrollAccountantExportTemplate from "@/graphql/payroll_accountant_export_template.gql";
import PayrollAccountantTemplate from "@/graphql/payroll_accountant_template.gql";
import ClientLog from "@/graphql/client_log.gql";
import ClientEmployeeContract from "@/graphql/client_employee_contract.gql";
import EmailTemplate from "@/graphql/email_template.gql";
import Comment from "@/graphql/comment.gql";
import ClientAppliedDocument from "@/graphql/client_applied_document.gql";
import WorktimeRegister from "@/graphql/worktime_register.gql";
import Media from "@/graphql/media.gql";
import Contract from "@/graphql/contract.gql";
import ContractTemplate from "@/graphql/contract_template.gql";
import ClientDepartment from "@/graphql/client_department.gql";
import ClientPosition from "@/graphql/client_position.gql";
import AllowanceGroup from "@/graphql/allowance_group.gql";
import Allowance from "@/graphql/allowance.gql";
import Setting from "@/graphql/setting.gql";
import MobileSetting from "@/graphql/mobile_setting.gql";
import ClientDepartmentEmployee from "@/graphql/client_department_employee.gql";
import PaidLeaveChange from "@/graphql/paid_leave_change.gql";
import AssignmentProject from "@/graphql/assignment_project.gql";
import AssignmentProjectUser from "@/graphql/assignment_project_user.gql";
import AssignmentTask from "@/graphql/assignment_task.gql";
import SocialSecurityClaimTracking from "@/graphql/social_security_claim_tracking.gql";
import AppliedDocumentCategory from "@/graphql/applied_document_category.gql";
import DebitSetup from "@/graphql/debit_setup.gql";
import DebitRequest from "@/graphql/debit_request.gql";
import DebitHistory from "@/graphql/debit_history.gql";
import ReportPit from "@/graphql/report_pit.gql";
import SocialSecurityProfileRequest from "@/graphql/social_security_profile_request.gql";
import ClientEmployeeGroup from "@/graphql/client_employee_group.gql";
import ActionLog from "@/graphql/action_log.gql";
import Slider from "@/graphql/slider.gql";
import MobileVersion from "@/graphql/mobile_version.gql";
import ClientEmployeeGroupAssignment from "@/graphql/client_employee_group_assignment.gql";
import Bank from "@/graphql/bank.gql";
import Suppliers from "@/graphql/suppliers.gql";
import Beneficiary from '@/graphql/beneficiary.gql';
import Billing from '@/graphql/billing.gql'
import BillingContractTemplate from '@/graphql/billing_contract_template.gql'
import BillingContract from '@/graphql/billing_contract.gql'
import BillingDebitNote from '@/graphql/billing_debit_note.gql'
import BillingSetting from '@/graphql/billing_setting.gql'
import SocialSecurityAgencies from '@/graphql/social_security_agencies.gql';
import FederationOfLabor from '@/graphql/federation_of_labor.gql';
import ProvinceBank from '@/graphql/province_bank.gql';
import Provinces from '@/graphql/provinces.gql';
import ProvinceDistricts from '@/graphql/province_districts.gql';
import ShuiUnionFee from '@/graphql/shui_union_fee.gql';
import HeadCountPeriod from '@/graphql/headcount_period.gql';
import ApproveFlows from "@/graphql/approve_flows.gql";
import SocialSecurityAccount from '@/graphql/social_security_account.gql';
import ImportablePITEmployee from '@/graphql/importable_pit_employee.gql';
import GroupApproval from '@/graphql/group_approval.gql';
import ClientEmployeeLeaveRequest from '@/graphql/client_employee_leave_request.gql'
import WebVersion from '@/graphql/web_version.gql'
import WebFeatureSlider from '@/graphql/web_feature_slider.gql'
import ImportablePITData from '@/graphql/importable_pit_data.gql';
import Emanual from '@/graphql/emanual.gql';
import ClientBranch from '@/graphql/client_branch.gql';
import ReportPitClientEmployee from "@/graphql/report_pit_client_employee.gql";
import ActivityLog from '@/graphql/activity_log.gql';

const schemas = {
  Auth,
  User,
  Client,
  ClientEmployee,
  ClientEmployeeContract,
  Timesheet,
  ClientCustomVariable,
  ClientEmployeeCustomVariable,
  ClientLog,
  CalculationSheetTemplate,
  CalculationSheetClientEmployee,
  CalculationSheetExportTemplate,
  CalculationSheetVariable,
  CalculationSheet,
  IglocalEmployee,
  IglocalAssignment,
  SupportTicket,
  SupportTicketComment,
  Formula,
  WorkSchedule,
  Report,
  WorkScheduleGroup,
  WorkScheduleGroupTemplate,
  Approve,
  ClientWorkflowSetting,
  Notification,
  KnowledgeQuestion,
  DebitNote,
  SocialSecurityProfile,
  Translation,
  CalculationSheetTemplateAssignment,
  YearHoliday,
  SocialSecurityClaim,
  PayrollAccountantExportTemplate,
  PayrollAccountantTemplate,
  EmailTemplate,
  Comment,
  ClientAppliedDocument,
  Media,
  WorktimeRegister,
  Contract,
  ContractTemplate,
  ClientDepartment,
  ClientPosition,
  AllowanceGroup,
  Allowance,
  Setting,
  ClientEmployeeDependent,
  ClientDepartmentEmployee,
  MobileSetting,
  SocialSecurityClaimTracking,
  PaidLeaveChange,
  AssignmentProject,
  AssignmentTask,
  AssignmentProjectUser,
  AppliedDocumentCategory,
  DebitSetup,
  DebitRequest,
  DebitHistory,
  ReportPit,
  SocialSecurityProfileRequest,
  ClientEmployeeGroup,
  ActionLog,
  Slider,
  MobileVersion,
  ClientEmployeeGroupAssignment,
  Bank,
  Suppliers,
  Beneficiary,
  Billing,
  BillingContractTemplate,
  BillingContract,
  BillingDebitNote,
  BillingSetting,
  SocialSecurityAgencies,
  FederationOfLabor,
  ProvinceBank,
  Provinces,
  ProvinceDistricts,
  ShuiUnionFee,
  HeadCountPeriod,
  ApproveFlows,
  SocialSecurityAccount,
  ImportablePITEmployee,
  GroupApproval,
  ClientEmployeeLeaveRequest,
  WebVersion,
  WebFeatureSlider,
  ImportablePITData,
  Emanual,
  ClientBranch,
  ReportPitClientEmployee,
  ActivityLog
};

const gqlQuery = (schema, action, variables, silent) => {
  return api.query(schemas[schema][action], variables, silent);
};

const gqlSingleQuery = (schema, action, variables, silent) => {
  return api.singleQuery(schemas[schema][action], variables, silent)
}

const gqlMutate = (schema, action, variables, silent) => {
  return api.mutate(schemas[schema][action], variables, silent);
};

const gqlSingleMutate = (schema, action, variables, silent) => {
  return api.singleMutate(schemas[schema][action], variables, silent);
}

const gqlUpload = (schema, action, variables) => {
  return api.upload(schemas[schema][action], variables);
};

const convertToSlug = (title) => {
  let slug = title.toLowerCase();

  // Đổi ký tự có dấu thành không dấu
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
  slug = slug.replace(/đ/gi, "d");
  // Xóa các ký tự đặt biệt
  slug = slug.replace(/[`~!@#|$%^&*()+=,./?><'":;_]/gi, "");
  // Đổi khoảng trắng thành ký tự gạch ngang
  slug = slug.replace(/ /gi, "_");
  // Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
  // Phòng trường hợp người nhập vào quá nhiều ký tự trắng
  slug = slug.replace(/-----/gi, "_");
  slug = slug.replace(/----/gi, "_");
  slug = slug.replace(/---/gi, "_");
  slug = slug.replace(/--/gi, "_");
  // Xóa các ký tự gạch ngang ở đầu và cuối
  slug = "@" + slug + "@";
  slug = slug.replace(/@-|-@|@/gi, "");

  return slug;
};

const formatUsernameForDisplay = (username) => {
  const usernames = username.split("_");

  if (usernames.length > 0) {
    const newUsername = remove(0, 1, usernames);

    return newUsername.join("_");
  }

  return username;
};

const getClientCodeFromUsername = (username) => {
  const usernames = username.split("_");

  if (usernames.length > 0) {
    return usernames[0];
  }

  return "";
};

const getDuration = (from_date, to_date) => {
  const startTime = moment(from_date);
  const endTime = moment(to_date);

  const duration = moment.duration(endTime.diff(startTime));
  const hours = duration.asHours();

  return hours;
};

const formatDateTime = (datetime, format) => {
  format = format || "DD/MM/YYYY HH:mm";
  return moment(datetime).format(format);
};

const handleError = function (err) {
  if (
    err.graphQLErrors &&
    err.graphQLErrors.length &&
    err.graphQLErrors[0].extensions &&
    err.graphQLErrors[0].extensions.validation
  ) {
    const validation = err.graphQLErrors[0].extensions.validation;
    if (
      validation.username &&
      validation.username.includes("validation.username_exists")
    ) {
      // Lỗi username trùng
      Swal.fire({
        title: this.$t("model.notifications.creating_employee_failed"),
        text: this.$t("model.notifications.duplicate_username"),
        icon: "error",
        confirmButtonText: "OK",
      }).then((confirm) => {
        this.loading = false;
      });
    } else {
      // Lỗi chung chung
      Swal.fire({
        title: this.$t("model.notifications.creating_employee_failed"),
        text: this.$t("model.notifications.please_check_form"),
        icon: "error",
        confirmButtonText: "OK",
      }).then((confirm) => {
        this.loading = false;
        this.$refs.formData.setErrors(
          err.graphQLErrors[0].extensions.validation
        );
      });
    }
  }
};

const toPrecision = function (value) {
  const v = value || 0;

  return Math.round(v * 100) / 100;
};

const formatMoney = function (value) {
  const v = Math.round(value);

  return new Intl.NumberFormat("vn-VI").format(v);
};

const getMoment = function (locale) {
  moment.suppressDeprecationWarnings = true;
  moment.locale(locale);

  return moment;
};

const downloadFileFromJson = (json) => {
  const rs = JSON.parse(json);
  const a = document.createElement("a");
  a.href = rs.file;
  a.download = rs.name;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const getEmployeeOneLineName = (clientEmployee) =>
  "[" + clientEmployee.code + "] " + clientEmployee.full_name;

export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const sessionTimeout = window.localStorage.getItem("session-timeout");

export const setSessionTimeout = function (minute) {
  console.log("Setting session timeout", minute);
  minute = parseInt(minute);
  if (minute && !isNaN(minute)) {
    window.localStorage.setItem("session-timeout", minute);
    console.log("Finish Set session timeout", minute);
  } else {
    console.log("");
    console.log("Remove session timeout");
    window.localStorage.removeItem("session-timeout");
  }
};

export default {
  gqlQuery,
  gqlSingleQuery,
  gqlMutate,
  gqlSingleMutate,
  gqlUpload,
  convertToSlug,
  formatUsernameForDisplay,
  getClientCodeFromUsername,
  getDuration,
  formatDateTime,
  formatMoney,
  handleError,
  toPrecision,
  getMoment,
  downloadFileFromJson,
  getEmployeeOneLineName,
  setSessionTimeout,
  sessionTimeout,
  getErrorCodeFromResponse,
};
